import * as React from "react"

import Seo from "../components/seo"

const IndexPage = () => (
  <div data-theme="forest">
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content text-center">
        <div className="max-w-md">
          <h1 className="text-5xl font-bold">Hello there!</h1>
          <p className="py-6 text-xl">Are you looking for the HUB?</p>
          <a className="btn btn-primary"  href="https://hub.mastrcheif.com">Right this way!</a>
        </div>
      </div>
    </div>
  </div>
)

export const Head = () => <Seo title="Mastrcheif" />

export default IndexPage
